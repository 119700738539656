// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "t_s3 d_gS d_cw d_dv";
export var quoteParagraphCenter = "t_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "t_gV d_gV d_cw d_dx";
export var quoteRowLeft = "t_s4 d_bG";
export var quoteRowCenter = "t_s5 d_bD";
export var quoteRowRight = "t_s6 d_bH";
export var quoteWrapper = "t_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "t_gR d_gR";
export var quoteExceptionSmall = "t_rT q_rT";
export var quoteExceptionNormal = "t_rV q_rV";
export var quoteExceptionLarge = "t_rW q_rW";
export var quoteAuthorExceptionSmall = "t_rX q_rX";
export var quoteAuthorExceptionNormal = "t_rY q_rY";
export var quoteAuthorExceptionLarge = "t_rZ q_rZ";