// extracted by mini-css-extract-plugin
export var alignDiscLeft = "l_py d_fp d_bG d_dv";
export var alignLeft = "l_pz d_fp d_bG d_dv";
export var alignDiscCenter = "l_pB d_fq d_bD d_dw";
export var alignCenter = "l_bP d_fq d_bD d_dw";
export var alignDiscRight = "l_pC d_fr d_bH d_dx";
export var alignRight = "l_pD d_fr d_bH d_dx";
export var footerContainer = "l_pF d_dW d_bW";
export var footerContainerFull = "l_pG d_dT d_bW";
export var footerHeader = "l_kf d_kf";
export var footerTextWrapper = "l_pH d_w";
export var footerDisclaimerWrapper = "l_km d_km d_ck";
export var badgeWrapper = "l_pJ d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "l_pK d_bz d_bJ d_bN d_bL";
export var wide = "l_pL d_cy";
export var right = "l_pM d_bK";
export var line = "l_fk d_fl d_cv";
export var badgeDisclaimer = "l_pN d_bC d_bP d_bJ";
export var badgeContainer = "l_pP d_bz d_bH d_bP";
export var badge = "l_pQ";
export var padding = "l_pR d_c7";
export var end = "l_pS d_bH";
export var linkWrapper = "l_pT d_dB";
export var link = "l_mC d_dB";
export var colWrapper = "l_pV d_cx";
export var consent = "l_f d_f d_bC d_bP";
export var disclaimer = "l_pW d_bz d_bJ";
export var media = "l_pX d_bx d_dy";
export var itemRight = "l_pY";
export var itemLeft = "l_pZ";
export var itemCenter = "l_p0";
export var exceptionWeight = "l_p1 q_rv";