// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "n_qK d_gv d_cs";
export var heroHeaderCenter = "n_gw d_gw d_cs d_dw";
export var heroHeaderRight = "n_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "n_qL d_gr d_cw";
export var heroParagraphCenter = "n_gs d_gs d_cw d_dw";
export var heroParagraphRight = "n_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "n_qM d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "n_qN d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "n_qP d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "n_qQ d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "n_qR d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "n_qS q_qS";
export var heroExceptionNormal = "n_qT q_qT";
export var heroExceptionLarge = "n_qV q_qV";
export var Title1Small = "n_qW q_qW q_q3 q_q4";
export var Title1Normal = "n_qX q_qX q_q3 q_q5";
export var Title1Large = "n_qY q_qY q_q3 q_q6";
export var BodySmall = "n_qZ q_qZ q_q3 q_rm";
export var BodyNormal = "n_q0 q_q0 q_q3 q_rn";
export var BodyLarge = "n_q1 q_q1 q_q3 q_rp";