// extracted by mini-css-extract-plugin
export var carouselContainer = "z_vd d_w d_H d_bf d_Z";
export var carouselContainerCards = "z_vf z_vd d_w d_H d_bf d_Z";
export var carouselContainerSides = "z_vg d_w d_H d_Z";
export var prevCarouselItem = "z_vh d_w d_H d_0 d_k";
export var prevCarouselItemL = "z_vj z_vh d_w d_H d_0 d_k";
export var moveInFromLeft = "z_vk";
export var prevCarouselItemR = "z_vl z_vh d_w d_H d_0 d_k";
export var moveInFromRight = "z_vm";
export var selectedCarouselItem = "z_vn d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "z_vp z_vn d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "z_vq z_vn d_w d_H d_Z d_bf";
export var nextCarouselItem = "z_vr d_w d_H d_0 d_k";
export var nextCarouselItemL = "z_vs z_vr d_w d_H d_0 d_k";
export var nextCarouselItemR = "z_vt z_vr d_w d_H d_0 d_k";
export var arrowContainer = "z_vv d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "z_vw z_vv d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "z_vx z_vw z_vv d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "z_vy z_vv d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "z_vz";
export var nextArrowContainerHidden = "z_vB z_vy z_vv d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "z_kG d_0";
export var prevArrow = "z_vC z_kG d_0";
export var nextArrow = "z_vD z_kG d_0";
export var carouselIndicatorContainer = "z_vF d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "z_vG d_w d_bz d_bF";
export var carouselText = "z_vH d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "z_vJ z_vH d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "z_vK d_b7";
export var carouselIndicator = "z_vL z_vK d_b7";
export var carouselIndicatorSelected = "z_vM z_vK d_b7";
export var arrowsContainerTopRight = "z_vN d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "z_vP d_0 d_bl d_bC";
export var arrowsContainerSides = "z_vQ d_0 d_bl d_bC";
export var smallArrowsBase = "z_vR d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "z_vS z_vR d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "z_vT z_vS z_vR d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "z_vV z_vR d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "z_vW z_vV z_vR d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "z_qC";
export var iconSmall = "z_vX";
export var multipleWrapper = "z_vY d_bC d_bF d_bf";
export var multipleImage = "z_vZ d_bC";
export var sidesPrevContainer = "z_v0 z_vS z_vR d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "z_v1 z_vS z_vR d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";